$(function () {
	$('.js-counter-add').on('click', function() {
		const $input = $(this).prev().find('.js-counter-input');
		let inc = $input.data("inc");
		let currentValue = parseInt($input.val());
		$input.attr('value',currentValue + inc);
		$(this).prev().find('.js-counter-input').val(currentValue + inc);

		$(this).closest('.product-actions').find('.js-add-to-card').attr("data-quantity",currentValue + inc); 

		$(this).closest(".cart-item__num").find(".qty").val(currentValue + inc);
		$( '[name="update_cart"]').prop("disabled", false);

		$( '[name="update_cart"]' ).trigger( 'click' );
	});

	$('.js-counter-sub').on('click', function() {
		const $input = $(this).next().find('.js-counter-input');
		let inc = $input.data("inc");
		let currentValue = parseInt($input.val()); 
		if (currentValue >= inc*2) { 
			$input.attr('value',currentValue - inc);
			$(this).next().find('.js-counter-input').val(currentValue - inc);

			$(this).closest('.product-actions').find('.js-add-to-card').attr("data-quantity",currentValue - inc); 

			$(this).closest(".cart-item__num").find(".qty").val(currentValue - inc);
			$('[name="update_cart"]').prop("disabled", false);

			$('[name="update_cart"]' ).trigger( 'click' );  
		}
	});

});
 