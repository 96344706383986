import './ui-elements'
import './form'
import './popup'
import './animation'

$(function () {



  Fancybox.bind("[data-fancybox]", {});
  console.log(Fancybox);

	if($("div").is(".product-main-init")){ 
		var galleryThumbs = new Swiper('.product-thumb-init', {
			spaceBetween: 20,		
			slidesPerView: 6,
			freeMode: true,	
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				1300: {
				spaceBetween: 10
				}
			},
			navigation: {
				nextEl: '.thumb-next',
				prevEl: '.thumb-prev',
			},
		});
		var galleryTop = new Swiper('.product-main-init', {
			spaceBetween: 0,	
			slidesPerView: 1,		
			thumbs: {
				swiper: galleryThumbs
			}
		});
	}

  var shareslider = new Swiper('.share-slider-init', {
    spaceBetween: 0,		
    slidesPerView: 1,
    // freeMode: true,	
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    // breakpoints: {
    //   1300: {
    //   spaceBetween: 10
    //   }
    // },
    pagination: {
      el: '.share-pagination',
      type: 'bullets',
    },
    // navigation: {
    //   nextEl: '.thumb-next',
    //   prevEl: '.thumb-prev',
    // },
  });




  var analyticsslider = new Swiper('.analytics-slider-init', {
    spaceBetween: 0,		
    slidesPerView: 1,
    pagination: {
      el: '.analytics-pagination',
      type: 'bullets',
    },
  });

  var reviewslider = new Swiper('.reviews-slider-init', {
    spaceBetween: 20,		
    slidesPerView: 2,
    // freeMode: true,	
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    // breakpoints: {
    //   1300: {
    //   spaceBetween: 10
    //   }
    // },
    pagination: {
      el: '.reviews-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.reviews-next',
      prevEl: '.reviews-prev',
    },
  });

  var newslider = new Swiper('.news-slider-init', {
    spaceBetween: 20,		
    slidesPerView: 3,
    // freeMode: true,	
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 1.3,
      }
    }, 
    // pagination: {
    //   el: '.reviews-pagination', 
    //   type: 'bullets',
    // },
    navigation: { 
      nextEl: '.news-next',
      prevEl: '.news-prev',
    },
  });


  var team_items = new Swiper('.team-slider-init', {
    spaceBetween: 30,		
    slidesPerView: 3,
    
    // freeMode: true,	
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 10,		

      }
    }, 
    // pagination: {
    //   el: '.reviews-pagination',
    //   type: 'bullets',
    // },
    navigation: { 
      nextEl: '.t-next',
      prevEl: '.t-prev',
    },
  });

  var partner_items = new Swiper('.partners-slider-init', {
    spaceBetween: 20,		
    slidesPerView: 4,
    // centeredSlides: true,
    // freeMode: true,	
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        // centeredSlides: true,
      }
    }, 
    // pagination: {
    //   el: '.reviews-pagination',
    //   type: 'bullets',
    // },
    // navigation: { 
    //   nextEl: '.news-next',
    //   prevEl: '.news-prev',
    // },
  });


  var partner_items = new Swiper('.js-banner-slider', {
    spaceBetween: 0,		
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
    },
    // centeredSlides: true,
    // freeMode: true,	
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        // centeredSlides: true,
      }
    }, 
    pagination: {
      el: '.banner-pagination',
      type: 'bullets',
    },
    // navigation: { 
    //   nextEl: '.news-next',
    //   prevEl: '.news-prev',
    // },
  });

  $('.js-nav-btn').on('click',function(){	
    var $this = $(this);
    var navBlock = $('.catalog-menu');
    $this.toggleClass('burger_active');
      if ($this.hasClass('burger_active')) {
        navBlock.addClass("active");
        // navBlock.animate({"left":"0px"});	
      } else {
        // navBlock.animate({"left":"-100%"});	
        navBlock.removeClass("active");
      } 

  });

  $('.js-open-catalog').click(function(e){
    var navBlock = $('.catalog-menu');
    e.preventDefault();
    $('.js-nav-btn').toggleClass('burger_active');
    if ($('.js-nav-btn').hasClass('burger_active')) {
      navBlock.addClass("active");
      // navBlock.animate({"left":"0px"});	
    } else {
      // navBlock.animate({"left":"-100%"});	
      navBlock.removeClass("active");
    } 
  });

  $('.js-close-menu').on('click',function(){	
    var $this = $(this);
    $('.js-nav-btn').removeClass("burger_active");
    var navBlock = $('.js-menu-wrap');
    $this.toggleClass('burger_active');
    if ($this.hasClass('burger_active')) {
        navBlock.animate({"left":"0px"});	
      } else {
        navBlock.animate({"left":"-100%"});	
      } 

  });
 

  if($('.home').length) {
    $(".js-menu-anchor a").click(function (e) {
      if($(window).width()<=992){
        var navBlock = $('.js-menu-wrap');
        navBlock.animate({"left":"-100%"});	
        $('.js-nav-btn').removeClass('burger_active');
      }
      
      e.preventDefault();
      var _href = $(this).attr("href").split('#').pop();	
      console.log(_href);
      $("html, body").animate({
        scrollTop: ($("#"+_href).offset().top-50) + "px"
      });
      return false;
    });
  } 

  $(window).scroll(function(){
    var aTop = 0;
    var navblock= $('header'); 
    if($(this).scrollTop()>aTop){
      navblock.addClass('fixed');
    } else{
      navblock.removeClass('fixed');
    }
  });

  $(".js-anchor").click(function () {
    var _href = $(this).attr("href");
    $("html, body").animate({
      scrollTop: ($(_href).offset().top-50) + "px"
    });
    return false;
  });

  $(".js-open-support").click(function () {
    var _href = $(this).find("a").attr("href").substring(1);

    $('.js-popup[data-block="'+_href+'"]').addClass('is-show');

    return false; 
  });




  // $('.js-portfolio-btn').on("click",function(e){

  //   e.preventDefault();

  //   $('.js-portfolio-btn').removeClass("active");

  //   $(this).addClass("active");

  //   let data={
  //     'term_id': $(this).data('term_id'), 
  //     'action': 'portfolio_filter',
  //     'count': $(this).data('count'),
  //   };
  //   console.log(data); 

  //   $.ajax({
  //     type: 'POST',
  //     url: '/wp-admin/admin-ajax.php',
  //     data: data, 
  //     error: function (jqXHR, exception) {
  //     },
  //     beforeSend : function ( xhr ) {		
      
  //     },
  //     success : function( data ){   
  //       console.log(data);
  //       if (data) {
  //         $('.portfolio-items').html(data);        
  //       } else { }
  //     }
  //   });

  // });



  

  $('.faq-item__header').click(function(event){			  
    event.preventDefault();   
    $(this).parent().toggleClass("opened");
  });


    
  if (($(".product-slider").length > 0 )) {

    var doneslider = new Swiper( this.querySelector('.main-slider'), {
      spaceBetween: 0,
      autoHeight: true,
      navigation: {
        nextEl: this.querySelector('.psm_next'),
        prevEl: this.querySelector('.psm_prev'),
      },
          loop: true,
          loopedSlides: 4
      });

      $('.js-slide-to').click(function(e) {
        e.preventDefault();
        let index=$(this).data("slide");
        console.log(index);
        doneslider.slideTo(index, 300);
      });

  }





  $('.js-product-cat').click(function(e) {

    e.preventDefault(); 
    $('.js-product-cat').removeClass("active");
    $(this).addClass("active");
    let term_id= $(this).data('term_id'); 
    let blog_id= $(this).data('blog_id'); 
    let lang= $(this).data('lang'); 

    let count= $(this).data('count'); 
    let data={
      'term_id': term_id, 
      'blog_id' : blog_id, 
      'lang': lang,
      'action': 'car_filter',
      'count': count,
    };

    console.log(data);

    if(term_id!=0)  $('.pag').addClass("d-none"); else $('.pag').removeClass("d-none");
   
  
    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: data, 
      error: function (jqXHR, exception) {
      },
      beforeSend : function ( xhr ) {		
      
      },
      success : function( data ){   
        console.log(data);
        if (data) {
          $('.js-product-items').html(data);
          sliders_init();
        
        } else { }
      }
    });

  });
  



  $('.js-back').click(function(e){
    e.preventDefault();
    history.back();
  });

  if(($(window).width()<768 )){	
    $('ul.main-menu > .menu-item-has-children').click(function(e){
      // e.preventDefault();
      e.stopPropagation();      
        if(e.target.tagName!="A" ) {
          $(this).toggleClass('opened');
          $(this).find('>ul.sub-menu').slideToggle();      
        }
    });
  }

  $('.js-all-options').click(function(e){
    e.preventDefault();
    $(this).toggleClass("opened"); 
    $(this).closest('.product-card').find('.product-info__row.hidden').slideToggle();
  });

  $('.js-view-all').click(function(e){
    e.preventDefault();
    $(this).toggleClass("opened"); 
    $(this).closest('.product-card').find('.product-info__row.hidden').slideToggle();
  });


  // window.addEventListener('load', function() {
  //   const preloader = document.getElementById('preloader');
  //   preloader.style.display = 'none';
  // }) 

  

  $('.faq-items .faq-item').on("click",function(e){
    e.preventDefault();
    $(this).toggleClass("active");		
    $('.faq-items .faq-item').not(this).find('.faq-item__body').slideUp();	
    $(this).find('.faq-item__body').slideToggle();
  });


  $('.js-tab').on("click",function(e){
    e.preventDefault();

    if(!$(this).hasClass("tab-accent")) {
      let tab=$(this).data("tab");	   
      $('.js-tab').removeClass("active");
      $(this).addClass("active");
      $('.content-tab').removeClass("active");
      $(".content-tab[data-tab="+tab+"]").addClass("active");
    }
    
  });

  // $('.js-open-note').hover(function(e){
  //   e.preventDefault();
  //   let note=$(this).data("note");	
  //   console.log(note);
  //   $(".note[data-note="+note+"]").addClass("active");    
  // });
  // $('.js-open-note').ble(function(e){
   
  // });
  

  $(document).on("click",".js-add-to-card", function(e) {
		e.preventDefault(); 
		let $thisbutton = $(this);
	
		let product_qty = $thisbutton.data("quantity");
		let product_id = $thisbutton.data("product_id");
		let variation_id = $thisbutton.data("variation_id");

		let data = {
				action: 'ql_woocommerce_ajax_add_to_cart',
				product_id: product_id,
				product_sku: '',
				quantity: product_qty,
				variation_id : variation_id
			};
      console.log(data);
		$.ajax({
				type: 'post',
				url: '/wp-admin/admin-ajax.php',
				data: data,
				beforeSend: function (response) {
					// $thisbutton.removeClass('added').addClass('loading');
				},
				complete: function (response) {
					// $thisbutton.addClass('added').removeClass('loading');
          // $thisbutton.html('Добавлено');
				}, 
				success: function (response) {  
          console.log(response);
					if (response.error & response.product_url) {
						window.location = response.product_url;
						return;
					} else { 
						$(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
					
						$('.js-basket-result').html(response.fragments.basket);  

						$('.added-info').html(response.fragments.add_to_card_notification); 

              const $elem = $('.js-popup[data-block="popup-cartadded"]');          
              $elem.addClass('is-show');
						// $('.added-info').animate({"right":"0px"}); 
						  $('.added-info').html(response.fragments.modal_card);
						setTimeout(function(){
							// $('.added-info').animate({"right":"-200px"});
              $('.added-info').html("");
              $elem.removeClass('is-show');
						},5000);
					
					} 
				}, 
	}); 




}); 


function set_fav_links(){
	if (typeof(localStorage) == 'undefined') {
		document.getElementById("result").innerHTML =
			'';
		} else {
		let fav_count=0;
		for(let i=0; i<localStorage.length; i++) {
			let key = localStorage.key(i);			
			if(parseInt(key)) {
				let object= JSON.parse(localStorage.getItem(key));	
				if(object.type=="product") {
					fav_count++;
				}
			}							
		}							
		if(fav_count != 0) {
			$('.fav-notificate').removeClass('hidden');
			$('.js-fav-count').text(fav_count);
		} else {
			$('.js-fav-count').text('');
			$('.fav-notificate').addClass('hidden');
		}
	}
}

set_fav_links();



  if($('.js-add-to-fav').length) {
    setadded();
  }
 
  function setadded(){
    if (typeof(localStorage) == 'undefined') {
      document.getElementById("result").innerHTML =
        'Your browser does not support HTML5 localStorage. Try upgrading.';
      } else {		
        $(".js-add-to-fav").each(function(i, el) {				
        let object = JSON.parse(localStorage.getItem($(this).data('postid')));
        if(object) {
          if (object.added == true) {				
            $(this).addClass('added');
            }
        }
        });
      }
  }

  if($('.js-fav-results').length) {
    let products=[];
    for(let i=0; i<localStorage.length; i++) {
      let key = localStorage.key(i);							 
      let object= JSON.parse(localStorage.getItem(key));				
      if(object.type=="product") {
        products.push(key);
      }
    }		
    let data = {
      action: 'loadfavorites',
      products: products,					
    } 	
   
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: data,
      type: 'POST', 
      error: function (jqXHR, exception) {
        var msg = '';
        alert(exception);			
      },
      beforeSend: function(){},
      success: function( data ) {		
        $('.js-fav-results').html(data);      
        setadded();
      }
    });	
  }	


$(document).on("click",".js-add-to-fav",function (e) {
	e.preventDefault();  	
	let $item=$(this);
	var postid = $(this).data('postid');		
	var type = $(this).data('type');

  if($item.closest(".product-item").length>0) {
	  $item.toggleClass('added');	
  }

	if ($item.hasClass('added')) {
		localStorage.setItem ( postid,
		JSON.stringify(
		{
		 'added': true,
		 'type' : type
		}
		));
	} 		 
	else { 
		localStorage.removeItem(postid); 
	}
	if($(this).closest('.favblock').length){
		$(this).closest('.col-sm-6').remove();
	}		
	set_fav_links();
});

$(document).on("click",".js-add-to-compare",function (e) {
	e.preventDefault();  	
	let $item=$(this);
	var postid = $(this).data('postid');		
	var type = $(this).data('type');
	$item.toggleClass('added');	
	if ($item.hasClass('added')) {
		localStorage.setItem ( postid,
		JSON.stringify(
		{
		 'added': true,
		 'type' : type
		}
		));
	} 		
	else {
		localStorage.removeItem(postid); 
	}	
});

$(document).on("click",".js-remove-compare",function (e) {
	var postid = $(this).data('postid');
	localStorage.removeItem(postid); 
	$(this).closest('.col-md-6').remove();
});




$(".go-up").on("click",function(event){
  event.preventDefault();

  var id  = $(this).attr('href'),
      top = $(id).offset().top;
  $('body,html').animate({scrollTop: top});
});




$('form.js-reg-form').submit(function(e) {
  e.preventDefault();
  let form_data = new FormData($(this)[0]);
  console.log(form_data);
  $.ajax({
      url: '/wp-admin/admin-ajax.php',
      data: form_data,
      type: 'POST',
      contentType: false,
      processData: false,		
      beforeSend: function() {
      },
      success: function(data) {
          if (data['success'] == false) {
              $('.result-info').addClass('error').html(data['data']['message']);
              $.each(data['data']['focus'], function(index, name) {
                  $('input[name='+name+']').addClass('focus');
              });	
          } else {      
              $('.result-info').removeClass('d-none');
          $('.result-info').html(data['data']['message']);
              setTimeout(function() {
                  window.location.replace(data['data']['redirect']);
              },4000);
          }
      }
  });
});

$('.js-login-form').submit(function(e) {
  e.preventDefault(); 
  let formData = new FormData($(this)[0]);
  $.ajax({
      url: '/wp-admin/admin-ajax.php',
      type: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      beforeSend: function() {
          $('input.focus').removeClass('focus');
      },
      success: function(data) {
          console.log(data); 
          if (data['success'] == false) {
              $('.result-info').addClass('error').html(data['data']['message']);
              $.each(data['data']['focus'], function(index, name) {
                  $('input[name="'+name+'"]').addClass('focus');
              });
          } else { 
              $('.result-info').html(data['data']['message']);
              // localStorage.setItem('user_id', data['data']['user_id']);
              setTimeout(function() {
                  window.location.replace(data['data']['redirect']);
              }, 4000);
          }
      },
      error: function(error) {
          $('.result-info').addClass('error').html(error);          
      }
  });
});


$('form.new-password-form').submit(function(e) {
  e.preventDefault();
  form=$(this);
  console.log(form); 
  let formData = new FormData($(this)[0]);			
  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData, 
    dataType: "json",
    beforeSend: function(){
      form.find('button[type=submit]').attr('disabled', 'disabled');
    },
    success: function(data){
      console.log(data);
      form.find('button[type=submit]').removeAttr('disabled');
      $('.js-form-message').text(data.message);	

      setTimeout(function(){
        $.fancybox.close();
        $('.js-form-message').text('');	
      },3000);
      

    },		
  });

});

$('form.recovery-form').submit(function(e) {
  e.preventDefault();
  form=$(this);
  console.log(form); 
  let formData = new FormData($(this)[0]);			
  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData, 
    dataType: "json",
    beforeSend: function(){
      form.find('button[type=submit]').attr('disabled', 'disabled');
    },
    success: function(data){
      console.log(data) 
      form.find('button[type=submit]').removeAttr('disabled');
      $('.js-form-message').text(data.message);	
              
      $.fancybox.open({
        src: '#modal_recovery_success',
        type: 'inline',
        'showCloseButton':false
      });
    },		
  });

});


$(document).on('input', 'input[name=invested]', function() {

  let invested=$(this).val();
  let income=invested*0.03-(invested*0.03*0.13);
  $('input[name=income]').val(Math.round(income)); 
  
});


window.preview = function(input) {
  if (input.filename && input.filename [0]) {
    jQuery(".photo_uploaded").html('');       
    jQuery(input.filename).each(function() {
      var reader = new FileReader();
      reader.readAsDataURL(this);
      reader.onload = function(e) {
        console.log('.e.target');
        jQuery(".file-upload-result").append("<img class='gallery-item' src='" + e.target.result + "'>");
      }
    });
  }
}


let eyes = document.querySelectorAll(".eye-btn"); 

eyes.forEach(eye => { 
  eye.addEventListener("click", function(e){
    var pwd = this.closest('.password-item').querySelector('.form-control');
    this.closest('.password-item').classList.toggle('opened'); 
    if(pwd.getAttribute("type")=="password"){
      pwd.setAttribute("type","text"); 
    } else {
      pwd.setAttribute("type","password");
    }
  });
});

$('a.js-retry-email').click(function(e) {
  e.preventDefault();
  let user_id = $(this).data('user');
  let user_email = $(this).data('email');
  let user_login = $(this).data('login');
  let action = 'user_email_verification';
  jQuery.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    data: {
      user_id: user_id,
      user_email: user_email,
      user_login: user_login,
      action: action
    },
    beforeSend: function() {},
    success: function(data) {
      $('.retry-info').html('Письмо направлено повторно. Проверьте вашу электронную почту '+user_email);
      setTimeout(function(){
        $('.retry-info').html('');
      },5000);
    }
  });
});

$(document).on("click",'.autor-data__actions a.edit', function(e){
  e.preventDefault();
  $(this).closest('.autor-data').find('input').prop("disabled", false);
  $(this).closest('.autor-data').find('input').focus();
});

$(document).on("blur",'.js-user-input', function(e){
  $(this).closest('.autor-data').find('input').prop("disabled", true);
  let fieldname=$(this).data('fieldname');
  let userid=$(this).data('userid');  
  let data={
    'action': 'update_user_data',		
    'fieldname': fieldname,	
    'filedvalue':$(this).val(),
    'userid': userid
  };	
  console.log(data);
  $.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    data: data,
    success : function( data ){
      console.log(data);    
    }
  });
});

$(document).on("click",'.js-edit-fields', function(e){
  e.preventDefault();
  $("input.can-edit").removeAttr("disabled");
  $(".js-save").removeClass("d-none");
});

$(document).on("click",'.js-save', function(e){
  e.preventDefault();
  $("input.can-edit").attr("disabled","disabled");
  $(this).addClass("d-none");
});



// create order

$('#place_order').click(function(e){		

  if($('#payment_method_cod').is(":checked")) 
    {
      e.preventDefault();

       let form_data = new FormData($('.woocommerce-checkout')[0]);
        console.log(form_data);
        form_data.append("action","create_order");
        jQuery.ajax({
          url: '/wp-admin/admin-ajax.php',
          data: form_data,
          type: 'POST',
          contentType: false,
          processData: false,		
            
          success: function(data) {
            console.log(data);
            if (data['success'] == false) {		
            } else {
              $.fancybox.open({
                src: '#modal_cart_success',
                type: 'inline',
                'showCloseButton':false
              });						
              setTimeout(function() {
                // $.fancybox.close();	
                location.reload();							
              }, 3000);
            }
          }
        });
  }

});
 

$('.tab-btn').click(function(e){

  e.preventDefault();
  $('.tab-btn').removeClass("active");
  $(this).addClass("active");
  let index=$(this).data("tab");
  $('.tab').removeClass("active");
  $('.tab[data-tab='+index+']').addClass("active");
   
}); 




  $('.zoomimg').imagezoomsl({ 
    zoomrange: [3, 3]	
  }); 
  
  $(document).on("click",".js-all-variation", function(e) {
		e.preventDefault(); 
    $('.product-variation.hidden').toggleClass("opened");
    $(this).text($(this).text() == "Показать все вариации" ? "Скрыть все вариации" : "Показать все вариации");
  });

  $(".switcher__input").on("change",function(e){
    e.preventDefault();
    if ($(this).is(":checked"))  {
        localStorage.setItem('dark',1);    
        $("body").addClass("dark");
      }  	 
      else {
        localStorage.removeItem('dark');
        $("body").removeClass("dark");
    }
  });


  function settheme(){
    if(localStorage.getItem('dark')==1){
      $("body").addClass("dark");
      $(".switcher__input").attr('checked','checked');
    }
  }

  settheme();

  // zoom

  $('.product__image-container').on('mouseover', function() {
    $(this).find('.product__zoom-lens').show();
    $('.product__zoom-container').show();
  });

  $('.product__image-container').on('mouseout', function() {
    $(this).find('.product__zoom-lens').hide();
    $('.product__zoom-container').hide();
  });

  // $('.product__image-container').on('mousemove', function(event) {
   
  //   let lens = $(this).find('.product__zoom-lens');
  //   let zoomImage = $('.product__zoom-container').find('.product__zoom-image');
  //   let img_url=$(this).find('.product__image').attr('src'); 

    
  //   let container = $('.product__zoom-container');

  //   let imageWidth = $(this).find(".product__image").width();
  //   let imageHeight = $(this).find(".product__image").width();

  //   let containerWidth = container.width();
  //   let containerHeight = container.height();



  //   let lensWidth = lens.width();
  //   let lensHeight = lens.height(); 

  //   // Координаты курсора относительно изображения
  //   let offsetX = event.pageX - $(this).offset().left;
  //   let offsetY = event.pageY - $(this).offset().top;

  //   // Позиционирование лупы
  //   lens.css({
  //     left: offsetX - lensWidth / 2,
  //     top: offsetY - lensHeight / 2
  //   });

  //   let scale=2;


  //   let zoomX = -offsetX *2* (imageWidth / containerWidth - 1);
  //   let zoomY = -offsetY *2* (imageHeight / containerHeight - 1);
    
  
  //   let style="transform: translate("+zoomX+"px, "+zoomY+"px); height:auto; width:"+imageWidth*2+"px; max-width:"+imageWidth*2+"px"; 
  //   zoomImage.attr('style', style);
  //   zoomImage.attr('src',img_url);   


  // });  

  // end zoom
  
}); 